/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import RelatedProductsCardPDP from '../../molecules/RelatedProductsCardPDP/RelatedProductsCardPDP';
import styles from './RelatedProductsPDP.module.scss';
import Button from '../../atoms/Button/Button';
import usePrices from '../../../Hooks/usePrices/usePrices';
import { convertStringIntoNumber } from '../../../../utils';
import ProductDetailRender from '../ProductDetailRender/ProductDetailRender';

const RelatedProductsPDP = ({
  products,
  title,
  label,
  productId,
  product,
  productPriceData,
  onClick,
}) => {
  const [prices, setPrices] = useState([]);
  const [totalPrice, setTotalPrices] = useState(0);
  const [currentProductPrice, setCurrentProductPrice] = useState(0);
  const [productIds, setproductIds] = useState([]);
  const containerRef = useRef(null);
  // console.log(productPriceData);

  const totalizeProducts = (pricesAndIds) => {
    const newArray = Object.entries(pricesAndIds).map((it) => it[1].value);
    return newArray.reduce((acc, curr) => acc + curr, 0);
  };

  const removeProducts = (ar, el, indexE) => {
    const newObjet = Object.entries(ar).filter(
      (it) => it[1].value !== el || indexE !== it[1].id,
    );
    return newObjet.map((it) => ({ ...it[1] }));
  };

  const removeProductsIds = (ar, el) => {
    const newArray = ar.filter(
      (it) => it !== el,
    );
    return newArray;
  };

  const renderProducts = (arrPro) => {
    const justThreeElements = arrPro.slice(0, 3);
    return justThreeElements.map((item, index) => {
      const newProps = usePrices(item?.variants[0]);

      const addPricesToState = (value) => {
        const convertedState = {
          ...value,
          value: convertStringIntoNumber(value.value),
        };
        setPrices((currentState) => [...currentState, convertedState]);
        setproductIds((currentState) => [...currentState, item?.variants[0].id]);
      };

      const addOrRemovePricesToState = (value, status) => {
        const convertedValue = convertStringIntoNumber(value.value);
        if (status) {
          addPricesToState(value);
        } else {
          setPrices(removeProducts(prices, convertedValue, value.id));
          setproductIds(
            removeProductsIds(
              productIds,
              item?.variants[0].id,
              value.id,
            ),
          );
        }
      };

      return (
        <RelatedProductsCardPDP
          key={index}
          {...newProps}
          name={item.name}
          keyIndex={index}
          productId={item?.id}
          getPrices={addPricesToState}
          changePrices={addOrRemovePricesToState}
        />
      );
    });
  };

  useEffect(() => {
    setCurrentProductPrice(
      convertStringIntoNumber(productPriceData?.regularPrice?.price),
    );
    setTotalPrices(totalizeProducts(prices) + currentProductPrice);
    if (containerRef.current) {
      containerRef.current.setAttribute('dycomponentname', title);
    }
  }, [prices]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <div
        className={`${styles.products} main-wrapper dyPersonalizationComponent`}
        ref={containerRef}
      >
        <RelatedProductsCardPDP
          key={null}
          {...productPriceData}
          name={product?.name}
          keyIndex={null}
          productId={productId}
          getPrices={() => {}}
          changePrices={() => {}}
          disabled
        />
        {renderProducts(products)}
        <div className={styles.total}>
          <span>
            Total: $
            {totalPrice.toLocaleString('es-CO')}
          </span>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClick(productIds)}
          >
            {label}
          </Button>
        </div>
      </div>
    </div>
  );
};
RelatedProductsPDP.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  productId: PropTypes.string,
  products: PropTypes.arrayOf({
    image: PropTypes.string,
    title: PropTypes.string,
    badge: PropTypes.string,
    prize: PropTypes.string,
    unitPrize: PropTypes.string,
    regularPrize: PropTypes.string,
  }),
  product: PropTypes.shape({
    name: PropTypes.string,
  }),
  productPriceData: PropTypes.shape({
    regularPrice: PropTypes.shape({
      price: PropTypes.string,
    }),
  }),
};

export default RelatedProductsPDP;
